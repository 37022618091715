import "./Header.css";
import React, { useState, useEffect } from "react";
import Darklogo from "../assets/header/logo.webp";
import "../App.css";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, visible]);


  return (
    <header>
      <div className={`header_ik w-100 d-flex ${visible ? '' : 'hidden'}`} >
        <div className="logo_container d-flex align-items-center">
         <a className="" href="/">
         <img
            src={require("../assets/header/logo.webp")}
            className=""
            alt=""
          />
         </a>
        </div>
        <input type="checkbox" id="menu-toggle"></input>
    <label for="menu-toggle" class="menu-icon">&#9776;</label>
        <div className="navs_container d-flex justify-content-between w-100 menu">
       
          <div className="icons_container d-flex align-items-center ml-5 ">
          <a href="/shop">
            <div className="coins_header hoveraction d-flex flex-column align-items-center">
              {" "}
             <div className="coins_header_img">
             
             <img
                src={require("../assets/header/coins_header.webp")}
                className="header_img"
                alt=""
              />
             </div>
              <div className="coins_header_txt d-flex justify-content-center">
                <p className="mb-0 header_img_txt ">Shop</p>
              </div>
            </div>
            </a>
            <a href="">

            <div className="coins_cards hoveraction d-flex flex-column align-items-center">
              {" "}
             <div className="coins_cards_img">
           <img
                src={require("../assets/header/coins_cards.webp")}
                className="header_img"
                alt=""
              />{" "}
             </div>
              <div className="coins_cards_txt d-flex justify-content-center">
                <p className="mb-0 header_img_txt ">Market</p>
              </div>
            </div>
           </a>
           <a href="/play-now">

            <div className="battle_header hoveraction d-flex flex-column align-items-center">
              {" "}
             <div className="battle_header_img">
            <img
                src={require("../assets/header/battle_header.webp")}
                className="header_img"
                alt=""
              />
             </div>
             
              <div className="battle_header_txt d-flex justify-content-center">
                <p className="mb-0 header_img_txt ">Battle!</p>
              </div>
            </div>
            </a>
            <a href="">

            <div className="help_header hoveraction d-flex flex-column align-items-center">
              {" "}
             <div className="help_header_img">
           <img
                src={require("../assets/header/help_header.webp")}
                className="header_img"
                alt=""
              />
             </div>
              <div className="help_header_txt d-flex justify-content-center">
                <p className="mb-0 header_img_txt ">Help</p>
              </div>
            </div>
           </a>

          </div>
          <div className="header_button d-flex align-items-center">
          <a href="/login" className="">
                <img
            src={require("../assets/header/header_logorregister_button.webp")}
            className="home_button"
            alt=""
          />
            </a>
          </div>
          
        </div>
      </div>
    </header>
  );
};

export default Header;
