import React, { useEffect, useRef, useState } from 'react';
import locomotiveScroll from 'locomotive-scroll';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Particlesbg from '../components/ParticlesBackground';
function Home() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true)
    }, 1000)
  });
  return (
    <>
       {loading==false ?
      <div className='loader_div d-flex align-items-center justify-content-center'>
        {/* <i class="fa-solid fa-sun fa-spin"></i> */}
        <i class="fa-light fa-spinner"></i>
      </div>:
      <>
      
    <main className='home_main'>

    <Header />
  
      <section className='home_bannersec d-flex align-items-center '>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 d-flex align-items-center justify-content-center'>
              <div className='home_bannersec_content d-flex flex-column text-white ' data-aos="fade-up"
     data-aos-duration="1000">
                <h1 className='mb-0'>Splinterlands</h1>
                <p className='mb-0 monospace_font'>The Next Generation Of Collectible Card Games</p>
                <a href="/play-now" className="">
                <img
            src={require("../assets/home/playnow_button.webp")}
            className="home_button"
            alt=""
          />
            </a>
              </div>
             
            </div>
            <div className='col-lg-6'>
              <div className='home_bannersec_img float_anime2_ik' data-aos="zoom-in" data-aos-duration="1000">
              <img
            src={require("../assets/home/banner_img.webp")}
            className="w-100"
            alt=""
          />
          <img
            src={require("../assets/home/banner_img_objects.png")}
            className="w-100 position-absolute float_anime_ik"
            alt=""
          />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='home_secondsec text-white' >
        <div className='container' data-aos="fade-up"
     data-aos-duration="1000">
          <div className='row'>
            <div className='col-lg-4  home_secondsec_card d-flex flex-column align-items-center text-center' data-aos="fade-up"
     data-aos-duration="1000"     data-aos-delay="200"     >
              <div className='home_secondsec_card1_title'>
                <h2 className=''>PLAY</h2>
                <p className='monospace_font'>Anytime</p>
              </div>
              <div className='home_secondsec_card1_card d-flex flex-column align-items-center'>
              <img
            src={require("../assets/home/battle_card.webp")}
            className="w-100"
            alt=""
          />
         
         <a href="" className="">
                <img
            src={require("../assets/home/aboutgameplay_button.webp")}
            className="home_button"
            alt=""
          />
            </a>
              </div>
            </div>
            <div className='col-lg-4  home_secondsec_card d-flex flex-column align-items-center text-center home_secondsec_card1_diff' data-aos="fade-up"
     data-aos-duration="1000"     data-aos-delay="300"     >
              <div className='home_secondsec_card1_title'>
                <h2 className=''>TRADE</h2>
                <p className='monospace_font'>Anytime</p>
              </div>
              <div className='home_secondsec_card2_card d-flex flex-column align-items-center'>
              <img
            src={require("../assets/home/cards_card.webp")}
            className="w-100"
            alt=""
          />
           
           <a href="" className="">
                <img
            src={require("../assets/home/aboutcard_button.webp")}
            className="home_button"
            alt=""
          />
            </a>
              </div>
            </div>
            <div className='col-lg-4  home_secondsec_card d-flex flex-column align-items-center text-center' data-aos="fade-up"
     data-aos-duration="1000"     data-aos-delay="400"     >
              <div className='home_secondsec_card1_title'>
                <h2 className=''>EARN</h2>
                <p className='monospace_font'>Every Win</p>
              </div>
              <div className='home_secondsec_card3_card d-flex flex-column align-items-center'>
              <img
            src={require("../assets/home/coins_card.webp")}
            className="w-100"
            alt=""
          />
          
          <a href="" className="">
                <img
            src={require("../assets/home/aboutreward_button.webp")}
            className="home_button"
            alt=""
          />
            </a>
              </div>
            </div>
          </div>
        </div>
        <div className='container map_bg' data-aos="fade-up"
     data-aos-duration="1000">
        <div className='home_secondsec_2row'>
            <div className=' row'>
              <div className='col-lg-6 d-flex align-itmes-center'>
                <div className='home_secondsec_2row_content'>
                <h2 className=''>MAGIC + MAYHEM</h2>
                <p className='monospace_font mb-0'>Since the days of the Splintering, the face of the world has been shaped by blood and power. As factions battle for control, primal energies are harnessed and unleashed.

Tired of card games that require a huge investment in time to play a single session? The rapid battles in Splinterlands are fast and furious, each game only taking a few minutes.

Play, Trade, and Earn anywhere at anytime. Splinterlands is available on both desktop and mobile devices.</p>
              
                </div>
                
                </div>
                <div className='col-lg-6'>
                <div className='home_secondsec_2row_img'>
                <img
            src={require("../assets/home/magicsec_img.webp")}
            className="w-100"
            alt=""
          />
          <img
            src={require("../assets/home/magicsec_img_objects.png")}
            className="w-100 position-absolute float_anime_ik "
            alt=""
          />
                </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    <section className='home_thirdsec text-white'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-9'>
          <div className='home_thirdsec_title text-center'>
            <h2 className=''>GAMING UNLEASHED</h2>
            <p className='monospace_font'>Other online collectible card games sacrifice the real world value of physical cards for the sake of convenience.We see a better way.</p>
          </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-lg-12 '>
          <div className='table_bg' data-aos="fade-up"
     data-aos-duration="1000">
          <table className='table-responsive'>
  <tr className=''>
    <th scope="col"></th>
    <th scope="col">PLAY</th>
    <th scope="col">TRADE / SELL</th>
    <th scope="col">EARN</th>
  </tr>
  <tr>
  <th scope="row">Physical Games</th>
    <td className='monospace_font text-danger'>Must be physically present to play</td>
    <td className='monospace_font text-danger'>Limited by physical shipping constraintss</td>
    <td className='monospace_font text-danger'>Professional Players Only</td>
  </tr>
  <tr>
  <th scope="row">Digital Games</th>
    <td>ANYTIME / ANYWHERE</td>
    <td className='monospace_font text-danger'>Not Allowed</td>
    <td className='monospace_font text-danger'>Professional Players Only</td>
  </tr>
  <tr>
  <th scope="row">Splinterlands</th>
    <td>ANYTIME / ANYWHERE</td>
    <td>ANYTIME / ANYWHERE</td>
    <td>EVERYONE / EVERY WIN</td>
  </tr>
</table>
          </div>
          </div>
        </div>
      </div>
    </section>

      <section className='home_forvictoeysec text-white'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-9'>
          <div className='home_forvictoeysec_title'>
            <h2 className=''>FOR VICTORY AND GLORY</h2>
            <p className='monospace_font'>Each battle has a different combination of combat rules, total Mana cap, and playable Elements!

Strategy is critical in creating the best team for the given situation.</p>
<a href="" className="">
                <img
            src={require("../assets/home/playnow_button.webp")}
            className="home_button"
            alt=""
          />
            </a>
          </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-7'></div>
          <div className='col-lg-5'>
            <div className='home_forvictoeysec_img ' data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="600">
          <img
            src={require("../assets/home/victorysec_img1.webp")}
            className="w-100"
            alt=""
          />
          <img
            src={require("../assets/home/victorysec_img1_objects.png")}
            className="w-100 position-absolute float_anime_ik"
            alt=""
          />
          </div>
          </div>
        </div>
       
      </div>
      </section>
    <section className='home_playtoearnsec text-white'>
      <div className='container-fluid'>
        <div className='row'>
        <div className='col-lg-12'>
        <div className='home_playtoearnsec_title text-center'>
              <h2 className=''>PLAY TO EARN</h2>
              <p className='monospace_font'>Have you ever wanted to be a professional Gamer? <br/> Now you can. </p>
            </div>
        
          </div>
        </div>
        <div className='home_playtoearnsec_cardarea'>
          <div className='row'>
<div className='col-lg-4 d-flex align-items-center' >
  <div className='home_playtoearnsec_card text-center' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
    <p className='monospace_font'>Splinterlands makes it easy to earn on a daily basis. Players can win rewards from Tournaments, Ranked play, and Quests! No matter what your skill level or collection size, there is always an opportunity to earn!</p>
  </div>
</div>
<div className='col-lg-4 d-flex align-items-center'>
  <div className='home_playtoearnsec_img float_anime2_ik' data-aos="fade-up"
     data-aos-duration="1000">
  <img
            src={require("../assets/home/p2esec_img.png")}
            className="w-100"
            alt=""
          />
           <img
            src={require("../assets/home/p2esec_img_objects.png")}
            className="w-100 position-absolute float_anime_ik"
            alt=""
          />
  </div>
</div>
<div className='col-lg-4 d-flex align-items-center' >
  <div className='home_playtoearnsec_card text-center' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
    <p className='monospace_font'>Among the many prizes you may win are collectible cards of varying rarity, card packs, magic potions, and Dark Energy Crystals (a tradable, digital currency used to buy items in the shop).</p>
  </div>
</div>
          </div>
        </div>
      </div>
    </section>
    <section className='home_poweredbysec text-white'>
      <div className='container'>
       
        <div className='row'>
         
          <div className='col-lg-12 text-center'>
          <div className='home_poweredbysec_title '>
              <h2 className='mb-3'>POWERED BY <br/>BLOCKCHAIN TECHNOLOGY</h2>
              <p className='monospace_font'>Blockchain technology is powering the future of gaming, allowing players to trade, sell, and lease their card assets.The cards in your collection are compatible with 3rd party marketplace platforms such as Open Sea, PeakMonsters, Monster Market, and elsewhere.</p>
            </div>
        
          </div>
        </div>
        <div className='home_poweredbysec_cardarea mt-5 ' data-aos="fade-up"
     data-aos-duration="1000">
          <div className='row justify-content-center'>
            <div className='col-lg-4'>
              <div className='home_poweredbysec_card d-flex flex-column align-items-center text-center' data-aos="fade-up"
     data-aos-duration="1000"     data-aos-delay="200">
              <img
            src={require("../assets/home/combine_card.webp")}
            className="w-100"
            alt=""
          />
          <h3 className=''> COMBINE CARDS TO<br/>GAIN LEVELS</h3>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='home_poweredbysec_card d-flex flex-column align-items-center text-center' data-aos="fade-up"
     data-aos-duration="1000"     data-aos-delay="300">
              <img
            src={require("../assets/home/convert_card.webp")}
            className="w-100"
            alt=""
          />
          <h3 className=''> CONVERT CARDS<br/>TO CRYPTO
</h3>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='home_poweredbysec_card d-flex flex-column align-items-center text-center' data-aos="fade-up"
     data-aos-duration="1000"     data-aos-delay="400">
              <img
            src={require("../assets/home/tradewithanyone_card.webp")}
            className="w-100"
            alt=""
          />
          <h3 className=''>TRADE WITH<br/>ANYONE
</h3>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='home_poweredbysec_card d-flex flex-column align-items-center text-center' data-aos="fade-up"
     data-aos-duration="1000"     data-aos-delay="500">
              <img
            src={require("../assets/home/sellonmultiple_card.webp")}
            className="w-100"
            alt=""
          />
          <h3 className=''> SELL ON MULTIPLE<br/>PLATFORMS
</h3>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='home_poweredbysec_card d-flex flex-column align-items-center text-center' data-aos="fade-up"
     data-aos-duration="1000"     data-aos-delay="600">
              <img
            src={require("../assets/home/rent_card.webp")}
            className="w-100"
            alt=""
          />
          <h3 className=''>RENT CARDS TO<br/>OTHER PLAYERS
</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
   
    <section className='home_gotcryptosec text-white'>
      <div className='container'>
        <div className='row'>
        
        <div className='col-lg-12 d-flex align-items-center'>
            <div className='home_gotcryptosec_title'>
              <h2 className=''>GOT CRYPTO?</h2>
              <p className='monospace_font'>Splinterlands makes it easy to get involved with cryptocurrency. In fact, a Hive cryptocurrency wallet is automatically generated for you upon registering on this website.</p>
              
            </div>
          </div>
        </div>
        <div className='home_gotcryptosec_cardarea mt-5 '>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='home_gotcryptosec_cardrow'>
                <div className='row'>
                  <div className='col-lg-6 d-flex'>
                    <div className='home_gotcryptosec_card d-flex align-items-center'>
                    <p className='monospace_font mb-0'>For veteran crypto users, you’ll be glad to know that Splinterlands has cross-compatibility with other blockchains such as Ethereum and WAX.</p>
             
                    </div>
                  </div>
                  <div className='col-lg-6 d-flex'>
                    <div className='home_gotcryptosec_card d-flex align-items-center'>
              <p className='monospace_font mb-0'>Buy in-game credits with a variety of cryptocurrencies, earn crypto and other digital assets, & earn HIVE cryptocurrenty tokens.</p>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='home_gotcryptosec_card d-flex align-items-center'>
              <p className='monospace_font mb-0'>Building your portfolio has never been so much fun!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='home_gotcryptosec_img'>
              <img
            src={require("../assets/home/gotcryptosec_img.webp")}
            className="w-100 float_anime2_ik"
            alt=""data-aos="zoom-in"
            data-aos-duration="1000"     data-aos-delay="1000" 
          />
           <img
            src={require("../assets/home/gotcryptosec_img_objects.png")}
            className="w-100 position-absolute float_anime_ik"
            alt=""data-aos="zoom-in"
            data-aos-duration="1000"     data-aos-delay="1000" 
          />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='home_knowledgesec text-white'>
      <div className='container'>
        <div className='row'>
        <div className='col-lg-6'>
                <div className='home_knowledgesec_img ' data-aos="fade-up"
     data-aos-duration="1000">
                <img 
            src={require("../assets/home/knowledgesec_img.webp")}
            className="w-100"
            alt=""
          />
           <img 
            src={require("../assets/home/knowledgesec_img_objects.png")}
            className="w-100 position-absolute float_anime_ik "
            alt=""
          />
                </div>
                </div>
              <div className='col-lg-6 d-flex align-itmes-center'>
                <div className='home_knowledgesec_content d-flex flex-column'>
                <h2 className='mb-0'>KNOWLEDGE IS POWER</h2>
                <p className='monospace_font mb-0'>Stay up-to-date on the latest news, promotions, and announcements. Subscribe to the official Splinterlands newsletter!</p>
<div className='home_knowledgesec_content_email_field'>
<input type="email" id="email" name="email" placeholder='Email'></input>
</div>
<a href="" className="">
                <img
            src={require("../assets/home/subscribe_button.webp")}
            className="home_button"
            alt=""
          />
            </a>

                </div>
                
                </div>
              
        </div>
      </div>
    </section>
    
    <Footer />
    </main>
    <Particlesbg/>
    </>
}
    </>
  );
};

export default Home;
