import React, {useCallback, loadSlim,} from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const Particlesbg = () => {
	const particlesInit = async (main) => {
		console.log(main);
		await loadFull(main);
	  };
	
	//   const particlesLoaded = (container) => {
	// 	console.log(container);
	//   };
return(
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        // loaded={particlesLoaded}
        options={{
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push"
              },
              onHover: {
                enable: true,
                mode: "repulse"
              },
              resize: true
            },
            modes: {
              push: {
                quantity: 4
              },
              repulse: {
                distance: 50,
                duration: 0.4
              }
            }
          },
          particles: {
            color: {
              value: "#FF6647"
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: false,
              opacity: 0.5,
              width: 1
            },
            collisions: {
              enable: true
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce"
              },
              random: false,
              speed: 3,
              straight: false
            },
            number: {
              density: {
                enable: true,
                area: 1000
              },
              value: 80
            },
            opacity: {
              value: 1
            },
            shape: {
              type: "circle"
            },
            size: {
              value: { min: 0.5, max: 3 }
            }
          },
          detectRetina: true
        }}
      />
    </>
);

};

export default Particlesbg;
