import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import locomotiveScroll from "locomotive-scroll";


function Test() {
  const scrollRef = React.createRef();

  useEffect(() => {
    const scroll = new locomotiveScroll({
      el: scrollRef.current,
      smooth: true
    });
  });

  return (
    <div className="App">
      <div className="scroll" ref={scrollRef}>
        <h1 data-scroll data-scroll-speed="1" data-scroll-position="top">
          Locomotive Scroll in React
        </h1>
        <h2
          data-scroll
          data-scroll-speed="2"
          data-scroll-position="top"
          data-scroll-direction="horizontal"
        >
          Ima go sideways
        </h2>
      </div>
      
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Test />, rootElement);

export default Test
