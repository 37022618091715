import React, { useEffect, useRef, useState } from "react";
import locomotiveScroll from "locomotive-scroll";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import Particlesbg from "../components/ParticlesBackground";

function Login() {
  function myFunction() {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000);
  });
  return (
    <>
      {loading == false ? (
        <div className="loader_div d-flex align-items-center justify-content-center">
          <i class="fa-solid fa-sun fa-spin"></i>
        </div>
      ) : (
        <main className="login_main text-white">
          <Header />
          <section className="login_bannersec d-flex align-items-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-2">
                  <div className="login_bannersec_container_float_img">
                    <img
                      src={require("../assets/home/login_floatcoins.png")}
                      className="w-100 position-absolute login_float"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div
                    className="login_bannersec_container d-flex flex-column align-items-center position-relative"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="10"
                  >
                    <div className="login_bannersec_container_logo d-flex justify-content-center">
                      <img
                        src={require("../assets/header/logo.webp")}
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="login_bannersec_container_tabarea d-flex flex-column align-items-center text-center w-100">
                      <div className="login_tabs_heading mb-4">
                        <ul className="nav nav-tabs">
                          <li>
                            <a
                              data-toggle="tab"
                              href="#login"
                              className="active"
                            >
                              Login
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#register">
                              Register
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="tab-content w-100 " id="pills-tabContent">
                        <div className="login_tab tab-pane active " id="login">
                          <div className="login_tabs_inputfields1 mb-4 ">
                            <div className="login_email_input_div d-flex align-items-center">
                              <img
                                src={require("../assets/login/login_username.webp")}
                                className="mr-2"
                                alt=""
                              />
                              <input
                                type="text"
                                placeholder="EMAIL / USERNAME"
                                name="email"
                                class="home_knowledgesec_content_email_field"
                              ></input>
                            </div>
                          </div>
                          <div className="login_tabs_inputfields2 mb-2">
                            <div className="login_email_input_div d-flex align-items-center">
                              <img
                                src={require("../assets/login/login_password.webp")}
                                className="mr-2"
                                alt=""
                              />
                              <div className="d-flex w-100">
                                <input
                                  type="password"
                                  placeholder="PASSWORD / POSTING KEY"
                                  name="password"
                                  class="home_knowledgesec_content_email_field"
                                  id="myInput"
                                ></input>
                                <img
                                  src={require("../assets/login/view_passwd.webp")}
                                  className="passwdshow_icon ml-2"
                                  alt=""
                                  onclick="myFunction()"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="login_tabs_forgotpwd mb-4">
                            <a href="/forgot-password">
                              <p className="monospace_font mb-0">
                                Forgot Password?
                              </p>
                            </a>
                          </div>
                          <div className="inputbelow d-flex justify-content-center mb-4">
                            <div className="login_tabs_button">
                              <a href="/play-now" className="">
                                <img
                                  src={require("../assets/login/centered_login-button.webp")}
                                  className="home_button"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                          {/* "walletlogin_div" this classname was used in multiple place so use it with main parent classname */}
                          <div className="walletlogin_div">
                            <p className="">ALTERNATIVE WALLET LOGIN</p>
                            <div className="walletlogin_div_cardarea">
                              <div className="row justify-content-center">
                                <div className="col-lg-4 d-flex justify-content-center">
                                  <div className="walletlogin_div_card">
                                    <a href="">
                                      <img
                                        src={require("../assets/login/metamaskwallet.webp")}
                                        className=""
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center">
                                  <div className="walletlogin_div_card">
                                    <a href="">
                                      <img
                                        src={require("../assets/login/wombatwallet.webp")}
                                        className=""
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center">
                                  <div className="walletlogin_div_card">
                                    <a href="">
                                      <img
                                        src={require("../assets/login/coinbasewallet.webp")}
                                        className=""
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="register_tabs tab-pane" id="register">
                          <div className="email_div d-flex align-items-center mb-4">
                            <div className="login_email_input_div d-flex align-items-center">
                              <img
                                src={require("../assets/login/mail_icon.webp")}
                                className="mr-2"
                                alt=""
                              />
                              <input
                                type="text"
                                placeholder="EMAIL"
                                name="email"
                                class="home_knowledgesec_content_email_field"
                              ></input>
                            </div>
                            <div className="login_email_input_div d-flex align-items-center">
                              <img
                                src={require("../assets/login/mail_icon.webp")}
                                className="mr-2"
                                alt=""
                              />
                              <input
                                type="text"
                                placeholder="CONFIRM EMAIL"
                                name="email"
                                class="home_knowledgesec_content_email_field"
                              ></input>
                            </div>
                          </div>

                          <div className="login_email_input_div d-flex align-items-center mb-4">
                            <img
                              src={require("../assets/login/referel_icon.webp")}
                              className="mr-2"
                              alt=""
                            />
                            <input
                              type="text"
                              placeholder="REFERRAL ACCOUNT"
                              name="referrer"
                              class="home_knowledgesec_content_email_field"
                            ></input>
                          </div>
                          <div className="login_tabs_inputfields2 mb-4">
                            <div className="login_email_input_div d-flex align-items-center">
                              <img
                                src={require("../assets/login/login_password.webp")}
                                className="mr-2"
                                alt=""
                              />
                              <div className="d-flex w-100">
                                <input
                                  type="password"
                                  placeholder="PASSWORD"
                                  name="password"
                                  class="home_knowledgesec_content_email_field"
                                  id="myInput"
                                ></input>
                                <img
                                  src={require("../assets/login/view_passwd.webp")}
                                  className="passwdshow_icon ml-2"
                                  alt=""
                                  onclick="myFunction()"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="login_tabs_inputfields2 mb-4">
                            <div className="login_email_input_div d-flex align-items-center">
                              <img
                                src={require("../assets/login/login_password.webp")}
                                className="mr-2"
                                alt=""
                              />
                              <div className="d-flex w-100">
                                <input
                                  type="password"
                                  placeholder="CONFIRM PASSWORD"
                                  name="password"
                                  class="home_knowledgesec_content_email_field"
                                  id="myInput"
                                ></input>
                                <img
                                  src={require("../assets/login/view_passwd.webp")}
                                  className="passwdshow_icon ml-2"
                                  alt=""
                                  onclick="myFunction()"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="checkbox_input d-flex align-items-center mb-4">
                            <input
                              type="checkbox"
                              class="ui-checkbox mr-3"
                            ></input>
                            <p className="monospace_font mb-0">
                              NOTIFY ME ABOUT UPDATES AND SPECIAL OFFERS
                            </p>
                          </div>
                          <div className="checkbox_input d-flex mb-4">
                            <input
                              type="checkbox"
                              class="ui-checkbox mr-3"
                            ></input>
                            <p className="monospace_font mb-0 text-left">
                              BY CHECKING THIS BOX, YOU ACCEPT THE{" "}
                              <a href="">
                                <span className="checkbox_span monospace_font">
                                  PRIVACY POLICY
                                </span>
                              </a>{" "}
                              AND{" "}
                              <a href="">
                                <span className="checkbox_span monospace_font">
                                  TERMS OF SERVICE
                                </span>
                              </a>
                              .
                            </p>
                          </div>
                          {/* <ReCAPTCHA
                            sitekey="6Le2UHIiAAAAANZd3wVF9ypHuDYgGoGVXuwOLhsS"
                            className="px-3 d-flex justify-content-center mb-4"
                          /> */}
                          <div className="register_button_div mb-4">
                            <a href="/play-now" className="">
                              <img
                                src={require("../assets/login/register_button.webp")}
                                className="home_button"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="walletlogin_div">
                            <p className="">OR REGISTER WITH</p>
                            <div className="walletlogin_div_cardarea">
                              <div className="row justify-content-center">
                                <div className="col-lg-4 d-flex justify-content-center">
                                  <div className="walletlogin_div_card">
                                    <a href="">
                                      <img
                                        src={require("../assets/login/metamaskwallet.webp")}
                                        className=""
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center">
                                  <div className="walletlogin_div_card">
                                    <a href="">
                                      <img
                                        src={require("../assets/login/wombatwallet.webp")}
                                        className=""
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center">
                                  <div className="walletlogin_div_card">
                                    <a href="">
                                      <img
                                        src={require("../assets/login/coinbasewallet.webp")}
                                        className=""
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 d-flex align-items-center">
                  <div className="login_bannersec_container_float_img">
                    <img
                      src={require("../assets/home/login_floatcoins2.png")}
                      className="w-100 position-absolute login_float"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Particlesbg />

          <Footer />
        </main>
      )}
    </>
  );
}

export default Login;
