import React, { useEffect, useRef, useState } from "react";
import locomotiveScroll from "locomotive-scroll";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import Particlesbg from "../components/ParticlesBackground";

function Login() {
  function myFunction() {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000);
  });
  return (
    <>
      {loading == false ? (
        <div className="loader_div d-flex align-items-center justify-content-center">
          <i class="fa-solid fa-sun fa-spin"></i>
        </div>
      ) : (
        <main className="login_main text-white">
          <Header />
          <section className="login_bannersec d-flex align-items-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-7">
                  <div className="login_bannersec_container d-flex flex-column align-items-center text-center">
                    <div className="login_bannersec_container_logo d-flex justify-content-center">
                      <img
                        src={require("../assets/header/logo.webp")}
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="recoverpswd_title">
                      <h2 className="mb-4 ">RECOVER PASSWORD</h2>
                    </div>
                    <div className="recoverpswd_content">
                      <p className="mb-4 monospace_font">
                        Please enter the email address associated with your
                        account. If you have lost your private keys please
                        contact us at{" "}
                        <a
                          href="https://support.splinterlands.com"
                          className="monospace_font text-white"
                        >
                          https://support.splinterlands.com
                        </a>{" "}
                        for help.
                      </p>
                    </div>
                    <div className="recoverpswd_email w-100">
                      <div className="login_tabs_inputfields1 mb-2">
                        <div className="login_email_input_div d-flex align-items-center">
                          <img
                            src={require("../assets/login/mail_icon.webp")}
                            className="mr-2"
                            alt=""
                          />
                          <input
                            type="text"
                            placeholder="EMAIL"
                            name="email"
                            class="home_knowledgesec_content_email_field"
                          ></input>
                        </div>
                      </div>
                      <p className="monospace_font text-right">
                        <a href="/Login" className="monospace_font">
                          LOG IN WITH EMAIL / PASSWORD
                        </a>
                      </p>
                    </div>
                    {/* <div className="mb-4">
                      <ReCAPTCHA
                        sitekey="6Le2UHIiAAAAANZd3wVF9ypHuDYgGoGVXuwOLhsS"
                        className="px-3 d-flex justify-content-center"
                      />
                    </div> */}
                    <div className="register_button_div mb-4">
                      <a href="" className="">
                        <img
                          src={require("../assets/login/recover_password_button.webp")}
                          className="home_button"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="walletlogin_div">
                      <p className="">ALTERNATIVE WALLET LOGIN</p>
                      <div className="walletlogin_div_cardarea">
                        <div className="row justify-content-center">
                          <div className="col-lg-4 d-flex justify-content-center">
                            <div className="walletlogin_div_card">
                              <a href="">
                                <img
                                  src={require("../assets/login/metamaskwallet.webp")}
                                  className=""
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-4 d-flex justify-content-center">
                            <div className="walletlogin_div_card">
                              <a href="">
                                <img
                                  src={require("../assets/login/wombatwallet.webp")}
                                  className=""
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-4 d-flex justify-content-center">
                            <div className="walletlogin_div_card">
                              <a href="">
                                <img
                                  src={require("../assets/login/coinbasewallet.webp")}
                                  className=""
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Particlesbg />
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </main>
      )}
    </>
  );
}

export default Login;
