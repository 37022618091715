import React, { useEffect, useRef, useState } from "react";
import locomotiveScroll from "locomotive-scroll";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Shop() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true)
    }, 1000)
  });

return (
    <>
     

      {loading==false ?
      <div className='loader_div d-flex align-items-center justify-content-center'>
        <i class="fa-solid fa-sun fa-spin"></i>
      </div>:
      <main className="shop_main text-white">
      <Header />
      <input type="checkbox" id="menu-toggle"></input>
    <label for="menu-toggle" class="menu-icon shop_menu">
    <div class="dropdown d-flex justify-content-center">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Packs
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#">Gems</a>
    <a class="dropdown-item" href="#">Soul Keep</a>
    <a class="dropdown-item" href="#">Lisenses</a>
    <a class="dropdown-item" href="#">Potions</a>
    <a class="dropdown-item" href="#">Skins</a>

  </div>
</div> </label>
        <section className="shop_tabsec menu">
                <div className="tabs_headings">
                <ul className="nav nav-tabs">
                        <li>
                          <a data-toggle="tab" href="#" className="active">
                            Packs
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#">
                            Gems
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#">
                            Soulkeep
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#">
                            Licenses
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#">
                            Potions
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#">
                            Skins
                          </a>
                        </li>
                      </ul>
                </div>
        </section>
        <section className="shop_contentsec">
          <div className="container">
            <div className="row">
                <div className="col-lg-5">
                    <div className="shop_contentsec_img float_anime2_ik" data-aos="fade-up"
     data-aos-duration="1000">
                    <img
            src={require("../assets/shop/forest_spirit.webp")}
            className=""
            alt=""
          />
           <img
            src={require("../assets/shop/forest_spirit_objects.webp")}
            className="forest_spirit_objects"
            alt=""
          />
                    </div>
                </div>
                <div className="col-lg-7">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="shop_contentsec_card1 d-flex align-items-center mb-4">
                    <div className="shop_contentsec_card1_img">
                    <img
            src={require("../assets/shop/majestic_tree.webp")}
            className=""
            alt=""
          />
                            </div>
                            <div className="shop_contentsec_card1_content">
                                <h2 className="">ENCHANTED WOODS</h2>
                                <p className="mb-0 monospace_font">Welcome to the Splinterlands. The Spirit of the Forest offers you the opportunity to learn the secrets to becoming a Splinter Master. Your destiny begins with the mysteries and magic in the Summoner's Spellbook!
</p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="shop_contentsec_card2 mb-4">
                        <div className="shop_contentsec_card2_title d-flex align-items-center justify-content-between mb-4">
                            <h2 className="mb-0">SUMMONER'S SPELLBOOK</h2>
                            <p className="monospace_font prize mb-0">$10.00</p>
                        </div>
                        <div className="shop_contentsec_card2_content d-flex align-items-center mb-4">
                        <img
            src={require("../assets/shop/majestic_book.webp")}
            className=""
            alt=""
          />
                            <p className="monospace_font prize mb-0">Purchasing a Summoner’s Spellbook allows you, the Battle Mage, to harness the elemental magic contained within its pages.<br/>With it, you can<span className="text-success monospace_font"> earn daily focus and season rewards, compete in tournaments, purchase packs, battle your way to higher leagues and even better rewards, and more!</span></p>

                        </div>
                    <div className="shop_contentsec_button d-flex justify-content-center mb-4">
                    <a href="" className=" ">
                              <img
                                src={require("../assets/shop/learnmore_button.webp")}
                                className="home_button"
                                alt=""
                              />
                            </a>
                    </div>
                    {/* <div className="shop_payment_div">
                          <p className="monospace_font">Unlock the magic of the Splinterlands now!</p>
                          <div className="shop_payment_cardarea">
                            <div className="row justify-content-center">
                              <div className="col-lg-4 align-items-center d-flex justify-content-center flex-column text-center">
                                <div className="shop_payment_card">
                                  <h2 className="mb-0">CODE</h2>
                                </div>
                                <p className="monospace_font">Gift Card or Promo Code</p>
                              </div>
                              <div className="col-lg-4 align-items-center d-flex justify-content-center flex-column text-center">
                                <div className="shop_payment_card">
                                <a href="" className=" ">
                              <img
                                src={require("../assets/shop/paypal.webp")}
                                className="w-100"
                                alt=""
                              />
                            </a>
                                </div>
                                <p className="monospace_font">VISA, MasterCard, AmEx</p>
                              </div>
                              <div className="col-lg-4 align-items-center d-flex justify-content-center flex-column text-center">
                                <div className="shop_payment_card">
                                  <h2 className="mb-0">CRYPRO</h2>
                                </div>
                                <p className="monospace_font">HIVE, BUSD, WAX, DAI,<br/>+ more</p>
                              </div>
                            </div>
                          </div>
                        </div> */}
                         <div className="walletlogin_div">
                          <p className="text-center">Unlock the magic of the Splinterlands now!</p>
                          <div className="walletlogin_div_cardarea">
                            <div className="row justify-content-center align-items-start">
                              <div className="col-lg-4 d-flex justify-content-center flex-column align-items-center text-center">
                                <div className="walletlogin_div_card">
                                  <a href="">
                                    <h3 className="mb-0">CODE</h3>
                                  </a>
                                </div>
                                <p className="monospace_font border_null mt-2">Gift Card or Promo Code</p>
                              </div>
                              <div className="col-lg-4 d-flex justify-content-center flex-column align-items-center text-center">
                                <div className="walletlogin_div_card">
                                  <a href="">
                                    <img
                                      src={require("../assets/shop/paypal.webp")}
                                      className=""
                                      alt=""
                                    />
                                  </a>
                                </div>
                                <p className="monospace_font border_null mt-2">VISA, MasterCard, AmEx</p>
                              </div>
                              <div className="col-lg-4 d-flex justify-content-center flex-column align-items-center text-center">
                                <div className="walletlogin_div_card">
                                  <a href="">
                                  <h3 className="mb-0">CRYPTO</h3>

                                  </a>
                                </div>
                                <p className="monospace_font border_null mt-2">HIVE, BUSD, WAX, DAI, + more</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="shop_payment_def">
                            <p className="monospace_font">Gift Card or Promo Code
</p>
                            <p className="monospace_font">VISA, MasterCard, AmEx
</p>
                            <p className="monospace_font">HIVE, BUSD, WAX, DAI, + more
</p>
                        </div> */}
                    </div>

                </div>
            </div>
          </div>
        </section>
      </main>
        }
      
      <Footer />
                        
    
    </>
  );
}

export default Shop;
