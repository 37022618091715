import "../components/Footer.css";
import React, { useState, useEffect } from "react";
function Footer() {
return(
    <>
    <main className="footer_main text-white text-center">
        <div className="container">
            <div className="row mb-5">
                <div className="col-lg-12">
                <div className="navs_container d-flex align-items-start justify-content-between w-100 text-center">
                  <div className="navpage_container d-flex justify-content-between">
                    <div className="navpage_container_column1 mr-5 text-left">
                    <p className="monospace_font"><a href="">Home</a></p>
                    <p className="monospace_font"><a href="">Shop</a></p>
                    <p className="monospace_font"><a href="">Market</a></p>
                    <p className="monospace_font"><a href="">Battle</a></p>
                    <p className="monospace_font"><a href="">Items</a></p>
                    </div>
                    <div className="navpage_container_column1 text-left ml-5">
                    <p className="monospace_font"><a href="">Events</a></p>
                    <p className="monospace_font"><a href="">Guilds</a></p>
                    <p className="monospace_font"><a href="">Land</a></p>
                    <p className="monospace_font"><a href="">Media Kit</a></p>
                    <p className="monospace_font"><a href="">Help</a></p>
                    </div>

                  </div>
          <div className="footer_icons_container d-flex align-items-center">
            <div className=" footer_hoveraction d-flex flex-column align-items-center">
              {" "}
             <div className="coins_footer_img">
             <i class="ri-file-paper-2-line footer_img footer_icon_diff"></i>
             </div>
              <div className="d-flex justify-content-center">
                <p className="mb-0 footer_img_txt monospace_font footer_img_txt_diff">Blog</p>
              </div>
            </div>
            <div className=" footer_hoveraction d-flex flex-column align-items-center">
              {" "}
             <div className="">
             <i class="ri-discord-line footer_img"></i>
             </div>
              <div className=" d-flex justify-content-center">
                <p className="mb-0 footer_img_txt monospace_font">Discord</p>
              </div>
            </div>
            <div className=" footer_hoveraction d-flex flex-column align-items-center">
              {" "}
             <div className="">
             <i class="ri-telegram-line footer_img"></i>
             </div>
              <div className=" d-flex justify-content-center">
                <p className="mb-0 footer_img_txt monospace_font">Telegram</p>
              </div>
            </div>
            <div className=" footer_hoveraction d-flex flex-column align-items-center">
              {" "}
             <div className="">
             <i class="ri-twitter-line footer_img"></i>
             </div>
              <div className=" d-flex justify-content-center">
                <p className="mb-0 footer_img_txt monospace_font">Twitter</p>
              </div>
            </div>
            <div className=" footer_hoveraction d-flex flex-column align-items-center">
              {" "}
             <div className="">
             <i class="ri-facebook-fill footer_img"></i>
             </div>
              <div className=" d-flex justify-content-center">
                <p className="mb-0 footer_img_txt monospace_font">Facebook</p>
              </div>
            </div>
            <div className="help_header footer_hoveraction d-flex flex-column align-items-center">
              {" "}
             <div className="">
             <i class="ri-store-2-line footer_img"></i>   
             </div>
              <div className=" d-flex justify-content-center">
                <p className="mb-0 footer_img_txt monospace_font">Store</p>
              </div>
            </div>
          </div>
          
        </div>
                </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="footer_contents d-flex justify-content-between align-items-center">
                    <p className="">© 2023 Splinterlands, all rights reserved.</p>
                    <p ><a href="" className="">Terms & Conditions</a> ❘ <a href="" className="monospace_font">Privacy Policy</a></p>
                </div>
              </div>
            </div>
        </div>
    </main>
    </>
)
}

export default Footer;
