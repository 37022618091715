import React, { useEffect } from 'react';
// import ScrollContent from './pages/Home';
import './App.css';
import Lenis from '@studio-freight/lenis';
import { gsap } from 'gsap'; // Import gsap
import { ScrollTrigger } from 'gsap/ScrollTrigger'; // Import ScrollTrigger
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Forgotpswd from "./pages/Forgotpswd";
import Playnow from "./pages/Playnow";
import Shop from "./pages/Shop";
import Test from "./pages/Test";
import AOS from "aos";
import "aos/dist/aos.css";
import { tsParticles } from 'tsparticles';



import Header from './components/Header';

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/login" element={<Login />} />
    <Route path="/forgot-password" element={<Forgotpswd />} />
    <Route path="/play-now" element={<Playnow />} />
    <Route path="/shop" element={<Shop />} />
    <Route path="/test" element={<Test />} />

    </Routes>
    </BrowserRouter>
    </>
  );
  }

export default App;
