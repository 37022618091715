import React, { useEffect, useRef, useState } from "react";
import locomotiveScroll from 'locomotive-scroll';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Particlesbg from '../components/ParticlesBackground';


function Home() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true)
    }, 1000)
  });
  return (
    <>
     {loading==false ?
      <div className='loader_div d-flex align-items-center justify-content-center'>
        <i class="fa-solid fa-sun fa-spin"></i>
      </div>:
    <main className='playnow_main'>
    <Header />
      <section className='playnow_bannersec d-flex align-items-center '>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 d-flex align-items-center justify-content-center'>
              <div className='playnow_bannersec_content d-flex flex-column text-white align-items-center text-center' data-aos="fade-up"
     data-aos-duration="1000">
                <div className='playnow_banner_logo'> 
                <img
                      src={require("../assets/header/logo.webp")}
                      className=""
                      alt=""
                    />
                </div>
                <p className='mb-4'>Welcome back Chief!<br/><span className='monospace_font text-white'>Click to play now the world of excitement and embark on your gaming adventure.<br/>Let the war begin!</span></p>
                <a href="" className="">
                <img
            src={require("../assets/home/playnow_button.webp")}
            className="home_button"
            alt=""
          />
            </a>
              </div>
             
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className="playnow_responsive_img">
            <img
            src={require("../assets/playnow/responsive_img.png")}
            className="w-100 float_anime2_ik"
            alt=""data-aos="zoom-in"
            data-aos-duration="1000"     data-aos-delay="200" 
          />
            </div>
          </div>
        </div>
      </section>
    <Particlesbg/>

    <Footer />
    </main>
}
    </>
  );
};

export default Home;
